import React from 'react';

const HumidityBar = ({ humidity }) => {
  // Calculate the position of the dot based on humidity
  const position = humidity * (100 / 100); // Adjusting humidity range to fit 0% to 100%

  // Define gradient colors based on humidity
  const blue = Math.floor(255 * (1 - (humidity / 100)));
  const backgroundColor = `rgb(0, ${blue}, 255)`;

  return (
    <div style={{ position: 'relative', width: '300px', height: '50px', backgroundColor: 'lightgray' }}>
      <div style={{ marginBottom: '20px' }}>Humedad</div>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '25%',
          background: `linear-gradient(to right, lightblue, blue)`,
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: `${position}%`,
            transform: 'translate(-50%, -50%)',
            width: '2px',
            height: '100%',
            backgroundColor: 'black',
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            top: '-20px',
            left: `${position}%`,
            transform: 'translate(-50%, 0)',
          }}
        >
          
          <span style={{ fontSize: '1em' }}>{humidity}%</span>
        </div>
      </div>
    </div>
  );
};

export default HumidityBar;
