import React from 'react';
import * as myutils from './myutils';

const WindSpeedBar = ({ windSpeed, windDirection }) => {
  // Calculate the position of the dot based on windSpeed
  const position = windSpeed * (100 / 200); // Adjusting windSpeed range to fit 0% to 100%

  // Define gradient colors based on windSpeed
  //const blue = Math.floor(255 * (1 - (windSpeed / 200)));
  //const backgroundColor = `rgb(0, ${blue}, 255)`;

  return (
    <div style={{ position: 'relative', width: '300px', height: '50px', backgroundColor: 'lightgray'}}>
      <div style={{ marginBottom: '20px' }}>Velocidad del Viento</div>
      
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '25%',
          background: `linear-gradient(to right, lightblue, blue)`,
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '0',
            left: `${position}%`,
            transform: 'translate(-50%, 0)',
            width: '2px',
            height: '100%',
            backgroundColor: 'black',
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            top: '-20px',
            left: `${position}%`,
            transform: 'translate(-50%, 0)',
            fontSize: '0.8em', // Adjusting font size
          }}
        >
           <span style={{ fontSize: '1em' }}>
            {windSpeed} km/h {windDirection}º {myutils.degToCompass(windDirection)}{' '}
            <div style={{ transform: `rotate(${windDirection+90}deg)`, display: 'inline-block', marginLeft: '5px', }}>&#8594;</div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default WindSpeedBar;
