import React from 'react';

const TemperatureBar = ({ temperature }) => {
  // Calculate the position of the dot based on temperature
  const position = (temperature + 20) * (100 / 80); // Adjusting temperature range to fit 0% to 100%

  // Define gradient colors based on temperature
  let backgroundColor = '';
  if (temperature <= -20) {
    backgroundColor = 'blue';
  } else if (temperature >= 60) {
    backgroundColor = 'red';
  } else {
    const blue = Math.floor(255 * (1 - (temperature + 20) / 80));
    const red = Math.floor(255 * ((temperature + 20) / 80));
    backgroundColor = `rgb(${red}, 0, ${blue})`;
  }

  return (
    <div style={{ position: 'relative', width: '300px', height: '50px', backgroundColor: 'lightgray', }}>
      <div style={{ marginBottom: '20px' }}>Temperatura</div>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '25%',
          background: `linear-gradient(to right, blue, red)`,
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: `${position}%`,
            transform: 'translate(-50%, -50%)',
            width: '2px',
            height: '100%',
            backgroundColor: 'black',
          }}
        ></div>
        <div
          style={{
            position: 'absolute',
            top: '-20px',
            left: `${position}%`,
            transform: 'translate(-50%, 0)',
          }}
        > 
          <span style={{ fontSize: '1em' }}>{temperature}°C</span>
        </div>
      </div>
    </div>
  );
};

export default TemperatureBar;
