export function degToCompass(degrees) {
    // Cardinal directions
    var directions = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE',
                  'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];

    // Convert degrees to a value between 0 and 360
    degrees = (degrees + 360) % 360;

    // Calculate the index of the closest cardinal direction
    var index = Math.round(degrees / (360.0 / directions.length)) % directions.length;

    return directions[index];
}

export function getColor(value) {
    // Ajusta el valor dentro del rango de 0 a 100
    value = Math.max(0, Math.min(100, value));

    // Define los colores base en formato hexadecimal
    const colors = [
        //[255, 255, 255], // Blanco
        [0, 191, 255],   // Celeste
        //[0, 0, 255],     // Azul
        [0, 255, 0],     // Verde
        [255, 255, 0],   // Amarillo
        [255, 0, 0],     // Rojo
        [255, 192, 203], // Rosa
        [148, 0, 211]    // Violeta
    ];

    // Calcula el índice del color base dependiendo del valor proporcionado
    let index = Math.floor(value / (100 / (colors.length - 1)));

    // Si el valor es mayor que 100, usa violeta
    if (value > 100) {
        index = colors.length - 1;
    }

    // Interpola entre los colores base según el valor proporcionado
    const lowerColor = colors[index];
    const upperColor = colors[index + 1] || colors[index]; // Si no hay color superior, usa el mismo color
    const ratio = (value % (100 / (colors.length - 1))) / (100 / (colors.length - 1));

    // Interpola los componentes de color RGB
    const red = Math.round(lowerColor[0] + (upperColor[0] - lowerColor[0]) * ratio);
    const green = Math.round(lowerColor[1] + (upperColor[1] - lowerColor[1]) * ratio);
    const blue = Math.round(lowerColor[2] + (upperColor[2] - lowerColor[2]) * ratio);

    // Convierte los componentes de color a formato hexadecimal y devuelve el color
    return '#' + ((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1);
}

export function getTemperatureGradient(ctx, temperatures) {
    const gradient = ctx.createLinearGradient(0, 0, 0, 400); // Define gradient along the vertical axis
  
    // Define colors for temperature range from -30 to 40
    const temperatureColors = [
      { temperature: -30, color: [0, 0, 255] },    // Blue
      { temperature: -10, color: [173, 216, 230] }, // Light blue
      { temperature: 5, color: [255, 255, 0] },     // Yellow
      { temperature: 15, color: [255, 165, 0] },    // Orange
      { temperature: 25, color: [255, 0, 0] },      // Red
      { temperature: 40, color: [255, 192, 203] }   // Rose
    ];
  
    // Iterate over each temperature value and add corresponding color to the gradient
    temperatures.forEach(temperature => {
      for (let i = 0; i < temperatureColors.length - 1; i++) {
        const lowerColor = temperatureColors[i].color;
        const upperColor = temperatureColors[i + 1].color;
        const lowerTemperature = temperatureColors[i].temperature;
        const upperTemperature = temperatureColors[i + 1].temperature;
  
        if (temperature >= lowerTemperature && temperature < upperTemperature) {
          const ratio = (temperature - lowerTemperature) / (upperTemperature - lowerTemperature);
          const red = Math.round(lowerColor[0] + (upperColor[0] - lowerColor[0]) * ratio);
          const green = Math.round(lowerColor[1] + (upperColor[1] - lowerColor[1]) * ratio);
          const blue = Math.round(lowerColor[2] + (upperColor[2] - lowerColor[2]) * ratio);
          gradient.addColorStop((temperature + 30) / 70, `rgb(${red},${green},${blue})`);
          break; // Exit loop after finding appropriate color range
        }
      }
    });
  
    return gradient;
  }
  
  

export function getHumidityGradient(ctx, humidities) {
    const gradient = ctx.createLinearGradient(0, 0, 0, 400); // Define gradient along the vertical axis
  
    // Define colors for humidity range from 0 to 100
    const humidityColors = [
      { humidity: 0, color: [173, 216, 230] },   // Light blue
      { humidity: 100, color: [0, 0, 255] }      // Blue
    ];
  
    // Iterate over each humidity value and add corresponding color to the gradient
    humidities.forEach(humidity => {
      for (let i = 0; i < humidityColors.length - 1; i++) {
        const lowerColor = humidityColors[i].color;
        const upperColor = humidityColors[i + 1].color;
        const lowerHumidity = humidityColors[i].humidity;
        const upperHumidity = humidityColors[i + 1].humidity;
  
        if (humidity >= lowerHumidity && humidity < upperHumidity) {
          const ratio = (humidity - lowerHumidity) / (upperHumidity - lowerHumidity);
          const red = Math.round(lowerColor[0] + (upperColor[0] - lowerColor[0]) * ratio);
          const green = Math.round(lowerColor[1] + (upperColor[1] - lowerColor[1]) * ratio);
          const blue = Math.round(lowerColor[2] + (upperColor[2] - lowerColor[2]) * ratio);
          gradient.addColorStop(humidity / 100, `rgb(${red},${green},${blue})`);
          break; // Exit loop after finding appropriate color range
        }
      }
    });
  
    return gradient;
  }
  

  export function getPresureGradient(ctx, presures) {
    const gradient = ctx.createLinearGradient(0, 0, 0, 400); // Define gradient along the vertical axis
  
    // Define colors for presure range from 0 to 100
    const presureColors = [
      { presure: 800, color: [173, 216, 230] },   // Light blue
      { presure: 1200, color: [0, 0, 255] }      // Blue
    ];
  
    // Iterate over each presure value and add corresponding color to the gradient
    presures.forEach(presure => {
      for (let i = 0; i < presureColors.length - 1; i++) {
        const lowerColor = presureColors[i].color;
        const upperColor = presureColors[i + 1].color;
        const lowerpresure = presureColors[i].presure;
        const upperpresure = presureColors[i + 1].presure;
  
        if (presure >= lowerpresure && presure < upperpresure) {
          const ratio = (presure - lowerpresure) / (upperpresure - lowerpresure);
          const red = Math.round(lowerColor[0] + (upperColor[0] - lowerColor[0]) * ratio);
          const green = Math.round(lowerColor[1] + (upperColor[1] - lowerColor[1]) * ratio);
          const blue = Math.round(lowerColor[2] + (upperColor[2] - lowerColor[2]) * ratio);
          gradient.addColorStop(presure / 1200, `rgb(${red},${green},${blue})`);
          break; // Exit loop after finding appropriate color range
        }
      }
    });
  
    return gradient;
  }
  