import React, { useState, useEffect } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import TemperatureBar from './temperature';
import HumidityBar from './humidity';
import WindSpeedBar from './windSpeed';
import * as myutils from './myutils';

function App() {
  const [, setSensorData] = useState([]);
  const [latestData, setLatestData] = useState(null);

  useEffect(() => {
    fetchSensorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSensorData = async () => {
    try {
      const response = await fetch('https://wsapi.pablosantamaria.com.ar/api/sensor-data/lasts/', {
        method: 'GET'
      });
      const data = await response.json();
      setSensorData(data);
      setLatestData(data[0]);
      drawCharts(data);
    } catch (error) {
      console.error('Error fetching sensor data:', error);
    }
  };

  const drawCharts = (data) => {
    const labels = data.map(entry => entry.timestamp);
    const temperatures = data.map(entry => entry.temperature);
    const humidity = data.map(entry => entry.humidity);
    const windSpeed = data.map(entry => entry.wind_speed);
    const windDirection = data.map(entry => entry.wind_direction);
    const presure = data.map(entry => entry.presure);
  
    const ctxTemperature = document.getElementById('temperatureChart').getContext('2d');
    const ctxHumidity = document.getElementById('humidityChart').getContext('2d');
    const ctxWindSpeed = document.getElementById('windChart').getContext('2d');
    const ctxPresure = document.getElementById('presureChart').getContext('2d');
  
    // Destroy existing charts if they exist
    if (window.temperatureChart instanceof Chart) {
      window.temperatureChart.destroy();
    }
    if (window.humidityChart instanceof Chart) {
      window.humidityChart.destroy();
    }
    if (window.windChart instanceof Chart) {
      window.windChart.destroy();
    }
    if (window.presureChart instanceof Chart) {
      window.presureChart.destroy();
    }
    // Create new charts for temperature, humidity, and wind speed
    const chartWidth = 400; // Set the width of the charts
    const chartHeight = 200; // Set the height of the charts

    // Set the width and height of the canvas elements using CSS
    document.getElementById('temperatureChart').style.width = `${chartWidth}px`;
    document.getElementById('temperatureChart').style.height = `${chartHeight}px`;
    document.getElementById('humidityChart').style.width = `${chartWidth}px`;
    document.getElementById('humidityChart').style.height = `${chartHeight}px`;
    document.getElementById('windChart').style.width = `${chartWidth}px`;
    document.getElementById('windChart').style.height = `${chartHeight}px`;
    document.getElementById('presureChart').style.width = `${chartWidth}px`;
    document.getElementById('presureChart').style.height = `${chartHeight}px`;

    // Create new charts for temperature, humidity, and wind speed
    /*
    function getGradient(ctx, chartArea, colors) {
      if (!chartArea) {
        // Handle the case when chartArea is undefined
        // You can return a default gradient or throw an error
        return 'default-gradient'; // Example of returning a default value
      }
      
      const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
      colors.forEach(color => gradient.addColorStop(color.stop, color.value));
    
      return gradient;
    }
    */
    /*
    const temperatureColors = [
      { value: 'red', stop: 1 },
      { value: 'yellow', stop: 0.5 },
      { value: 'blue', stop: 0 }
    ];
    */
   /*
    const humidityColors = [
      { value: 'lightblue', stop: 0 },
      { value: 'blue', stop: 1 }
    ];
    */
    /*
    const windSpeedColors = [
      { value: 'lightblue', stop: 0 },
      { value: 'blue', stop: 0.25 },
      { value: 'yellow', stop: 0.5 },
      { value: 'red', stop: 0.75 },
      { value: 'purple', stop: 1 },
    ];
    */
    
    
    // Create new charts for temperature, humidity, and wind speed
    window.temperatureChart = new Chart(ctxTemperature, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          label: 'Temperature (°C)',
          data: temperatures,
          borderColor: myutils.getTemperatureGradient(ctxTemperature, temperatures),
          backgroundColor: myutils.getTemperatureGradient(ctxTemperature, temperatures),
          fill: false
        }]
      },
      options: {
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'minute'
            }
          }
        }
      }
    });

    window.humidityChart = new Chart(ctxHumidity, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          label: 'Humidity (%)',
          data: humidity,
          borderColor: myutils.getHumidityGradient(ctxHumidity, humidity),
          backgroundColor: myutils.getHumidityGradient(ctxHumidity, humidity),
          fill: false
        }]
      },
      options: {
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'minute'
            }
          }
        }
      }
    });

    window.presureChart = new Chart(ctxPresure, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          label: 'Presure (hPa)',
          data: presure,
          borderColor: myutils.getPresureGradient(ctxPresure, presure),
          backgroundColor: myutils.getPresureGradient(ctxPresure, presure),
          fill: false
        }]
      },
      options: {
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'minute'
            }
          }
        }
      }
    });
    
    function createCanvasPointStyle(color) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const size = 25; // Adjust size as needed
      const arrowWidth = 10; // Width of the arrow
      
      // Set canvas size
      canvas.width = size;
      canvas.height = size;
      
      // Draw line
      ctx.strokeStyle = color;
      ctx.lineWidth = 2; // Adjust line width as needed
      ctx.beginPath();
      ctx.moveTo(size / 2, 0); // Move to top center
      ctx.lineTo(size / 2, size - arrowWidth); // Draw vertical line
      ctx.stroke();
      
      // Draw arrowhead
      ctx.fillStyle = color;
      ctx.beginPath();
      ctx.moveTo(size / 2 - arrowWidth / 2, size - arrowWidth); // Move to the left bottom corner of the arrowhead
      ctx.lineTo(size / 2, size); // Draw bottom center of the arrowhead
      ctx.lineTo(size / 2 + arrowWidth / 2, size - arrowWidth); // Draw the right bottom corner of the arrowhead
      ctx.closePath();
      ctx.fill();
      
      // Return the canvas element
      return canvas;
    }
    
    window.windChart = new Chart(ctxWindSpeed, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [{
          label: 'Wind Speed (km/h)',
          data: windSpeed,
          pointStyle: function(context) {
            const color = myutils.getColor(context.parsed.y);
            return createCanvasPointStyle(color);
          },
          rotation: function(context) {
            const index = context.dataIndex;
            const direction = windDirection[index];
            return direction;
          },

          fill: false,
          showLine: false
        }]
      },
      options: {
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'minute'
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                // Retrieve the wind direction for the current data point
                //const index = context.dataIndex;
                //const direction = windDirection[index];
                
                // Get the tooltip label text (wind speed)
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                const value = context.parsed.y;
                label += value;
    
                return label;
              },
              footer: function(tooltipItems, data) {
                // Retrieve the wind direction for the current data point
                const index = tooltipItems[0].dataIndex;
                const direction = windDirection[index];
    
                // Construct the footer string with wind direction information
                return '_  Wind Direction: ' +direction + ' ' + myutils.degToCompass(direction);
              }
            }
          }
        }
      }
    });  
    
    
  };
  
   

  return (
  <div className="App" style={{width:'900px',position:'absolute',left:'50%',padding: '10px',transform: 'translate(-50%)'}}>
      <h1>Sensor Data</h1>
      {latestData && (
        <div>
          <h2>Latest Data</h2>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: '3px' }}>
              <TemperatureBar temperature={latestData.temperature} />
            </div>
            <div style={{ marginRight: '3px' }}>
              <HumidityBar humidity={latestData.humidity} />
            </div>
            <div>
              <WindSpeedBar windSpeed={latestData.wind_speed} windDirection={latestData.wind_direction} />
            </div>
          </div>
        </div>
      )}
      

      <canvas id="temperatureChart" ></canvas>
      <canvas id="humidityChart" ></canvas>
      <canvas id="windChart" ></canvas>
      <canvas id="presureChart" ></canvas>
      
    </div>
  );
}

export default App;
